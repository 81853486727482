export default function mapLegacyStore(storeID) {
  const storeMap = {
    11021: 5, // Bacu Marketplace Calle 90
    113001: 43, // Bacu Colina
    113002: 42, // Bacu Titan
    113003: 6, // Bacu Nogal
    13747: 44, // Bacu City U
    16438: 45, // Bacu Marketplace Flormorado
    1762: 41, // Bacu Calle 109
    17843: 7, // Bacu Marketplace Zona G
  };

  return storeMap[storeID] ?? storeID;
}
